<template>
    <div>
        <v-card>
            <v-toolbar flat color="primary" dark>
                <v-toolbar-title>Estudios socioeconómicos</v-toolbar-title>

                <v-progress-linear
                    :active="loadingPac"
                    :indeterminate="loadingPac"
                    absolute
                    bottom
                    color="green accent-4"
                />

            </v-toolbar>
            <v-card-text>
                <v-sheet>
                    <v-row>
                        <v-col sm=3 cols="12">
                            <v-text-field
                                v-model.number="cip"
                                rounded
                                filled
                                dense
                                type="number"
                                label="Expediente"
                                :readonly="loadingPac"
                                append-outer-icon="mdi-magnify"
                                @click:append-outer="dialogBuscaPaciente=true"
                            />
                        </v-col>

                        <v-col sm=9 cols="12">
                            <v-text-field
                                v-model="pac.nombreCompleto"
                                dense
                                label="Nombre"
                                readonly
                            />
                        </v-col>

                        <v-col sm=3 cols="12">
                            <v-text-field
                                v-model="pac.edad"
                                dense
                                label="Edad"
                                readonly
                            />
                        </v-col>

                        <v-col sm=3 cols="12">
                            <v-text-field
                                v-model="pac.fechaNacimiento"
                                dense
                                label="Fecha de nacimiento"
                                readonly
                            />
                        </v-col>

                        <v-col sm=3 cols="12">
                            <v-text-field
                                v-model="pac.curp"
                                dense
                                label="C.U.R.P."
                                readonly
                                :rules="[value => !!value || 'Debe actualizar.']"
                            />
                        </v-col>

                        <v-col sm=3 cols="12" class="d-flex flex-row">
                            <v-text-field
                                v-model="pac.otrosNivelSe"
                                dense
                                label="Nivel S.E."
                                readonly
                                append-outer-icon="mdi-pencil"
                                @click:append-outer="abreEditaNivel"
                            />
                            <v-btn icon v-if="pac.otrosNivelSe" @click="imprime(cip)"><v-icon>mdi-printer</v-icon></v-btn>
                        </v-col>

                    </v-row>

                </v-sheet>
            </v-card-text>

        </v-card>

        <busca-paciente
            :dialogBuscaPaciente.sync="dialogBuscaPaciente"
            :paciente.sync="cip"
            :todos="true"
        />
        <datos-estudio
            :editaNivelDialog.sync="editaNivelDialog"
            :paciente="pac"
            @guardado="cargaPaciente(cip)"
        />
    </div>
</template>

<script>
export default {
    components:{
        'datos-estudio': () => import('@/components/tsocial/EditaNivelDialog'),
        'busca-paciente':() => import('@/components/hospital/buscaPaciente')
    },
    data:()=>({
        loadingPac:false,
        loadingPrint:false,
        dialogBuscaPaciente:false,
        pac:{},
        editaNivelDialog:false,
        cip:null,
    }),

    methods:{
        abreEditaNivel(){
            if (this.pac.pacienteId){
                this.editaNivelDialog=true
            }
        },
        async cargaPaciente(paciente){
            try{
                this.loadingPac=true
                let req = await this.$http({
                    url:'/pacientes/listV2',
                    method:'GET',
                    params:{id:paciente}
                })
                this.loadingPac=false
                let datos = req.data
                if (datos.pacienteId){
                    if (datos.curp){
                        let curplast = datos.curp.slice(-2)

                        if (curplast == 'XX'){
                            datos.curp=''
                        }
                    }
                    
                    this.pac = datos
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No existe',
                        text: 'No se encuentran registros para el paciente '+paciente,
                    })
                }
            }catch(err){
                this.loadingPac=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async imprime(cip){
            this.loadingPrint=true
            try{
                let estce = await this.$http({
                    url:'/hospital/listEstudioSe',
                    method:'GET',
                    params:{cip:cip}
                })
                let estudio = estce.data.root[0].id

                let dt = await this.$http({
                    url: '/hospital/imprimeEstudioSe',
                    timeout: 600000,
                    method: 'GET',
                    params:{id:estudio},
                    responseType: 'arraybuffer',
                })
                this.loadingPrint=false
                let qs1 = dt.request.responseURL.split('?')
                let vars = qs1[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function(v){
                    tmp = v.split('=');
                    if(tmp.length == 2)
                    getVars[tmp[0]] = tmp[1];
                });
                
                // Crea el archivo y lo manda
                var headers = dt.headers;
                var blob = new Blob([dt.data],{type:headers['content-type']});
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = getVars._name+'.pdf';
                link.click();
                
                
            } catch(err){
                this.loadingPrint=false
                this.$store.dispatch('security/revisaError',err)
            }
        }
        
    },
    watch:{
        cip(val){
            if (val >= 10000099){
                this.cargaPaciente(val)
            } else{
                this.pac = {}
            }
            
        },

    },
}
</script>

<style>

</style>